<template>
  <div>
    <div class="main">
      <div class="header">
        <div class="logo">
          <img src="../assets/logo.png" alt="">
        </div>
        <div></div>
        <div class="header_home header_home1">
          <div v-for="(item,index) in listLheadder" @click="tab_btn(index)" :key="item.name">{{ item.name }}</div>
        </div>
        <div class="header_home">
          <div></div>
          <div class="header_home2">
            <div @click="tabIcon('Twitter')">
              <img src="../assets/icon_tw.png" alt="">
            </div>
            <div>
              <img src="../assets/icon_te2.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="seion">
        <div class="seion_div">
          <div class="se_y">Adventure in a Parallel World Earn Profits on Telegram</div>
          <div>
            Unleash the Telegram bot and step into a captivating odyssey across the realm of swords and sorcery. As a
            wandering adventurer, your prowess in vanquishing monsters shall yield bountiful rewards.

          </div>
        </div>
        <div>
          <div class="bg_img">
            <img class="img img_bg" :class="'img' + (index + 1)" :key="index" v-for="(item, index) in bg_img_list"
              :src="item.imageUrl" alt="">

            <div class="bot_button">
              <div>
                Enter the Telegram Bot.

              </div>
              <div class="bot_bottonimg"><img src="../assets/icon_te1.png" alt="">Start your adventure now.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="coon" v-if="showSoon" :style="{ backgroundImage: `url(${jpg_cswz})` }">
        Coming soon
      </div>
    </div>

    <!-- :style="{ backgroundImage: `url(${item.back})` }" -->
    <div class="main mainTow" v-for="(item, index) in main_liat" :key="index">
      <div class="header headerdive">
        {{ item.name }}
      </div>
      <div class="seion">
        <div class="seion_div">
          <div class="coun">
            {{ item.coun }}
          </div>
          <div class="equipment_div">
            <div v-for="(itemdiv, index) in item.dataList" :key="itemdiv.name" class="box"
              :class="(item.dataList.length == 4 && index == 3) ? 'box2' : 'box1'">
              <img :src="itemdiv.imageUrl" @click="tabIcon(itemdiv.name)" alt="">
              <div class="equipment_name"  :style="{ backgroundImage: `url(${imageUrlEquipment})` }">{{ itemdiv.name }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="bg_img">
            <img class="img img_bg" :class="'img' + (index + 1)"  :key="index" v-for="(val, index) in item.bg_img_list"
              :src="val.imageUrl" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="worldMain">
      <div class="world1"><img src="../assets/logo.png" alt=""></div>
      <div class="world1"> A whole new world awaits you.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PcTrgp',
  props: {
    msg: String
  },
  data() {
    return {
      showSoon: false,
      jpg_cswz: require('@/assets/swz.png'),
      listLheadder: [
        {
          name: 'Docs'
        },
        {
          name: 'Dapp'
        },
        {
          name: 'Mint'
        }
      ],
      bg_img_list: [
        {
          imageUrl: require('@/assets/bg_tp1.png'),
        },
        {
          imageUrl: require('@/assets/bg_tp2.png'),
        }
      ],
      imageUrlEquipment: require('@/assets/bg_txt.png'),
      main_liat: [
        {
          name: 'Begin Adventure Here',
          coun: 'Welcome, new adventurer, to the Western County of this vast land. You are about to embark on an exciting and thrilling journey.',
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp7.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp8.png'),
            }
          ],
          dataList: [
            {
              name: 'Upgrade Gear',
              imageUrl: require('@/assets/icon_zb.png'),
            },
            {
              name: 'Defeat Monsters',
              imageUrl: require('@/assets/icon_gw.png'),
            },
            {
              name: 'Collect Loot',
              imageUrl: require('@/assets/icon_bx.png'),
            },
            {
              name: 'Complete Quests',
              imageUrl: require('@/assets/icon_rw.png'),
            },
            {
              name: 'Earn Rewards',
              imageUrl: require('@/assets/icon_btb.png'),
            },
          ]
        },
        {
          name: 'There are huge rewards in the adventure journey',
          coun: 'Assist the alliance garrison in defeating invading tribal forces and lurking dark powers in the shadows. Reap generous rewards, become a legend within the alliance, and gain substantial returns!',
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp4.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp3.png'),
            }
          ],
          dataList: [
            {
              name: 'Bountiful Spoils',
              imageUrl: require('@/assets/icon_jl.png'),
            },
            {
              name: 'TRPG coin',
              imageUrl: require('@/assets/icon_tb.png'),
            },
            {
              name: 'Kingdom Coins',
              imageUrl: require('@/assets/icon_kb.png'),
            },
          ]
        },
        {
          name: 'What is TRPG?',
          coun: 'TRPG, the pioneering adventure gameplay on Telegram, invites you to assume the role of an adventurer embarking on a journey across the vast Middle Earth. Integrating blockchain technology with tabletop role-playing game (TRPG) mechanics, TRPG crafts an engaging and rewarding gaming experience.',
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp5.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp6.png'),
            }
          ],
          dataList: [
            {
              name: 'Tabletop RPG',
              imageUrl: require('@/assets/icon_tr.png'),
            },
            {
              name: 'Blockchain',
              imageUrl: require('@/assets/icon_qkl.png'),
            },
            {
              name: 'Telegram',
              imageUrl: require('@/assets/icon_te.png'),
            },
          ]
        },
        {
          name: "Join the Adventurers' Guild",
          coun: "The TRPG community is under construction. Brave travelers, join the Adventurers' Guild now! Let's together build and pioneer a brand new world!",
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp3.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp1.png'),
            }
          ],
          dataList: [
            {
              name: 'Adventure',
              imageUrl: require('@/assets/icon_ks.png'),
            },
            {
              name: 'Twitter',
              imageUrl: require('@/assets/icon_tt.png'),
            },
            // {
            //   name: 'Discord',
            //   imageUrl: require('@/assets/icon_dis.png'),
            // },
            {
              name: 'Telegram',
              imageUrl: require('@/assets/icon_te.png'),
            },
          ]
        }
      ],

    };
  },
  methods: {
    tab_btn(val) {
      let that = this;
      if(val==0) return window.open('https://trpg-gold.gitbook.io/trpg.gold/')
      that.showSoon = true
      setTimeout(function () {
        that.showSoon = false
      }, 3000);
    },
    tabIcon(name){
      if(name=='Twitter') return window.open('https://twitter.com/trpggoldbot')
      
    },
  },
}
</script>

<style scoped lang="less">
.coon {
  font-family: pingfang-s;
  color: white;
  width: 10vw;
  background-size: 100% 100%;
  width: 12.2vw;
  font-size: 1.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vw;
  position: absolute;
  top: 1vw;
  left: 50%;
  margin-left: -6.2vw;
}

.worldMain {
  background: black;
  width: 100%;
  height: 18.52vw;

  .world1 {
    text-align: center;
    color: white;
    font-family: pingfang-s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;

    img {
      width: 15.9vw;
      height: 5.2vw;
      /* margin-bottom: 0.625rem; */
      margin-top: 5.6vw;
      margin-bottom: 1vw;
    }
  }
}


.main {
  width: 100%;

  padding-top: 4vw;

  .seion {
    margin: auto;
    width: 75%;
    margin-top: 4vw;
    display: grid;
    grid-template-columns: 60% 40%;
    color: white;
    font-size: 1.2vw;
    /* font-weight: 600; */
    height: 34vw;
    line-height: 1.5;

    .seion_div {
      width: 90%;

      .coun {
        margin-bottom: 2vw;
      }
    }

    .se_y {
      color: rgb(230, 197, 30);
      font-size: 2.5vw;
      margin-top: 2vw;
      margin-bottom: 2vw;
      font-family: pingfang-r;
      line-height: 1;
    }

    .bg_img {
      position: relative;
      display: flex;
      margin-left: 2vw;

      .img_bg {
        width: 23vw;
        height: 30vw;
        position: absolute;
      }

      .img1 {

        top: 2vw;
        right: -1vw;
      }
    }

    .bot_button {
      position: absolute;
      bottom: -30vw;
      left: -47vw;
    }

    .bot_bottonimg {
      width: 38vw;
      height: 5vw;
      background: url(../assets/btn.png) center no-repeat;
      background-size: 100% 100%;
      font-size: 1.8vw;
      color: black;
      /* font-weight: 600; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.2vw;
      font-family: pingfang-r;
      cursor: pointer;

      img {
        width: 2.5vw;
        height: 2.5vw;
        margin-right: 1vw;
      }
    }
  }

  .logo {
    width: 20vw;
    position: absolute;
    top: -1.5vw;

    img {
      height: 6.8vw;
    }
  }

  .header {
    position: relative;
    width: 80%;
    height: 5vw;
    background: url(../assets/bg_ba.png) center no-repeat;
    background-size: 100% 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 20vw 1fr 22vw;

    .header_home {
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: white;
      font-size: 1.8vw;
      /* font-weight: 600; */
      margin-bottom: 0.4vw;

      img {
        width: 1.8vw;
        height: 1.8vw;
        margin-right: 1vw;
      }

      span {
        color: rgb(99, 98, 97);
      }
    }

    .header_home1 {
      width: 72%;
      font-family: pingfang-r;

      div {
        cursor: pointer;
      }
    }

    .header_home2 {
      display: flex;
      width: 6vw;
      justify-content: space-around;

      div {
        cursor: pointer;
      }
    }


  }
}

.mainTow {
  .headerdive {
    font-family: pingfang-r;
    text-align: center;
    display: flex;
    justify-content: center;
    color: rgb(245, 210, 63);
    font-size: 2.5vw;
    /* font-weight: 600; */
    line-height: 4.5vw;

  }

  .equipment_div {
    text-align: center;

    .box {
      width: 10vw;
      display: inline-block;
      width: 11vw;
      margin-right: 3vw;
      margin-top: 2vw;

      img {
        width: 7vw;
        height: 7vw;
      }
    }

    .box:nth-child(3n),
    .box2 {
      margin-right: 0;
    }

    .equipment_name {
      background-size: 100% 100%;
      width: 100%;
      height: 2.5vw;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      /* 禁止文本换行 */
      // overflow: hidden;
      /* 隐藏超出容器的内容 */
      text-overflow: ellipsis;
      /* 显示省略号 */
    }
  }
}
</style>
