<template>
  <div>
    <div class="main">
      <div class="header">
        <div class="logo">
          <img src="../assets/logo.png" alt="">
        </div>
        <div class="header_home">
          <div class="header_div"></div>
          <div @click="tabIcon('Twitter')">
            <img src="../assets/icon_tw.png" alt="">
          </div>
          <div class="imgs">
            <img src="../assets/icon_te2.png" alt="">
          </div>
        </div>
      </div>
      <div class="header_home header_home1">
        <div v-for="(item,index) in listLheadder" @click="tab_btn(index)" :key="item.name">{{ item.name }}</div>
      </div>
      <div class="seion">
        <div class="seion_div">
          <div class="se_y">Adventure in a Parallel World Earn Profits on Telegram</div>
          <div>
            Unleash the Telegram bot and step into a captivating odyssey across the realm of swords and sorcery. As a
            wandering adventurer, your prowess in vanquishing monsters shall yield bountiful rewards.

          </div>
        </div>
        <div>
          <div class="bg_img">
            <img class="img img_bg" :class="'img' + (index + 1)" :key="index" v-for="(item, index) in bg_img_list"
              :src="item.imageUrl" alt="">
          </div>
        </div>
        <div class="bot_button">
          <div>
            Enter the Telegram Bot.

          </div>
          <div class="bot_bottonimg"><img src="../assets/icon_te1.png" alt="">Start your adventure now.</div>
        </div>
      </div>
      <div class="coon" v-if="showSoon" :style="{ backgroundImage: `url(${jpg_cswz})` }">
        Coming soon
      </div>
    </div>

    <!-- :style="{ backgroundImage: `url(${item.back})` }" -->
    <div class="main mainTow" v-for="(item, index) in main_liat" :key="index">
      <div class="mainList">
        <div class="headerdive">
          {{ item.name }}
        </div>
        <div class="seion">
          <div class="seion_div">
            <div class="coun">
              {{ item.coun }}
            </div>
            <div>
              <div class="bg_img">
                <img class="img img_bg" :class="'img' + (index + 1)" :key="index" v-for="(val, index) in item.bg_img_list"
                  :src="val.imageUrl" alt="">
              </div>
            </div>
            <div class="equipment_div">
              <div v-for="(itemdiv, index) in item.dataList" :key="itemdiv.name" class="box"
                :class="(item.dataList.length == 4 && index == 3) ? 'box2' : 'box1'">
                <img :src="itemdiv.imageUrl" @click="tabIcon(itemdiv.name)" alt="">
                <div class="equipment_name" :style="{ backgroundImage: `url(${imageUrlEquipment})` }">{{ itemdiv.name }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="worldMain">
      <div class="world1"><img src="../assets/logo.png" alt=""></div>
      <div class="world1"> A whole new world awaits you.</div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'MobileTrgp',
  props: {
    msg: String
  },
  data() {
    return {
      showSoon: false,
      jpg_cswz: require('@/assets/swz.png'),
      listLheadder: [
        {
          name: 'Docs'
        },
        {
          name: 'Dapp'
        },
        {
          name: 'Mint'
        }
      ],
      bg_img_list: [
        {
          imageUrl: require('@/assets/bg_tp1.png'),
        },
        {
          imageUrl: require('@/assets/bg_tp2.png'),
        }
      ],
      imageUrlEquipment: require('@/assets/bg_txt.png'),
      main_liat: [
        {
          name: 'Begin Adventure Here',
          coun: 'Welcome, new adventurer, to the Western County of this vast land. You are about to embark on an exciting and thrilling journey.',
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp7.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp8.png'),
            }
          ],
          dataList: [
            {
              name: 'Upgrade Gear',
              imageUrl: require('@/assets/icon_zb.png'),
            },
            {
              name: 'Defeat Monsters',
              imageUrl: require('@/assets/icon_gw.png'),
            },
            {
              name: 'Collect Loot',
              imageUrl: require('@/assets/icon_bx.png'),
            },
            {
              name: 'Complete Quests',
              imageUrl: require('@/assets/icon_rw.png'),
            },
            {
              name: 'Earn Rewards',
              imageUrl: require('@/assets/icon_btb.png'),
            },
          ]
        },
        {
          name: 'There are huge rewards in the adventure journey',
          coun: 'Assist the alliance garrison in defeating invading tribal forces and lurking dark powers in the shadows. Reap generous rewards, become a legend within the alliance, and gain substantial returns!',
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp4.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp3.png'),
            }
          ],
          dataList: [
            {
              name: 'Bountiful Spoils',
              imageUrl: require('@/assets/icon_jl.png'),
            },
            {
              name: 'TRPG coin',
              imageUrl: require('@/assets/icon_tb.png'),
            },
            {
              name: 'Kingdom Coins',
              imageUrl: require('@/assets/icon_kb.png'),
            },
          ]
        },
        {
          name: 'What is TRPG?',
          coun: 'TRPG, the pioneering adventure gameplay on Telegram, invites you to assume the role of an adventurer embarking on a journey across the vast Middle Earth. Integrating blockchain technology with tabletop role-playing game (TRPG) mechanics, TRPG crafts an engaging and rewarding gaming experience.',
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp5.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp6.png'),
            }
          ],
          dataList: [
            {
              name: 'Tabletop RPG',
              imageUrl: require('@/assets/icon_tr.png'),
            },
            {
              name: 'Blockchain',
              imageUrl: require('@/assets/icon_qkl.png'),
            },
            {
              name: 'Telegram',
              imageUrl: require('@/assets/icon_te.png'),
            },
          ]
        },
        {
          name: "Join the Adventurers' Guild",
          coun: "The TRPG community is under construction. Brave travelers, join the Adventurers' Guild now! Let's together build and pioneer a brand new world!",
          back: require('@/assets/bg_lxt.png'),
          bg_img_list: [
            {
              imageUrl: require('@/assets/bg_tp3.png'),
            },
            {
              imageUrl: require('@/assets/bg_tp1.png'),
            }
          ],
          dataList: [
            {
              name: 'Adventure',
              imageUrl: require('@/assets/icon_ks.png'),
            },
            {
              name: 'Twitter',
              imageUrl: require('@/assets/icon_tt.png'),
            },
            // {
            //   name: 'Discord',
            //   imageUrl: require('@/assets/icon_dis.png'),
            // },
            {
              name: 'Telegram',
              imageUrl: require('@/assets/icon_te.png'),
            },
          ]
        }
      ],

    };
  },
  methods: {
    tab_btn(val) {
      let that = this;
      if(val==0) return window.open('https://trpg-gold.gitbook.io/trpg.gold/')
      that.showSoon = true
      setTimeout(function () {
        that.showSoon = false
      }, 3000);
    },
    tabIcon(name){
      if(name=='Twitter') return window.open('https://twitter.com/trpggoldbot')
      
    },
  },
}
</script>
  
<style scoped lang="less">
.coon {
  font-family: pingfang-s;
  color: white;
  background-size: 100% 100%;
  width: 130px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  position: absolute;
  top: 5px;
  left: 50%;
  margin-left: -65px;
}

.worldMain {
  background: black;
  padding:10px 0;
  width: 100%;
  height:281px;

  .world1 {
    text-align: center;
    color: white;
    font-family: pingfang-s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    img {
      width:162px;
      height: 53.16px;
      margin-bottom: 16px;
      margin-top: 94px;
    }
  }
}

.main {
  width: 100%;

  padding-top: 40px;

  .header {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    background: url(../assets/header.jpg) center no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 37px;

    .header_home {
      display: flex;
      align-items: center;
      color: white;
      margin-top: 6px;

      span {
        color: rgb(99, 98, 97);
      }

      .header_div {
        margin-right: 10px;
      }

      img {
        width: 23px;
        height: 20px;
        margin: 0 5px;
      }

      .imgs {
        img {
          height: 23px;
        }
      }
    }



    .logo {
      img {
        width: 165px;
        margin-left: 3px;
      }

    }
  }

  .header_home1 {
    width: 90%;
    display: grid;
    color: white;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 18px;
    font-family: pingfang-r;
  }

  .seion {
    width: 83%;
    margin: auto;
    color: white;
    font-size: 14px;
    line-height: 1.4;

    .se_y {
      font-size: 22px;
      color: rgb(230, 197, 30);
      margin-bottom: 10px;
      font-family: pingfang-r;
    }

    .bg_img {
      position: relative;
      display: flex;
      /* align-items: center; */
      margin-left: 22px;
      margin-top: 20px;
      height: 310px;

      .img_bg {
        width: 68%;
        position: absolute;
      }

      .img1 {
        left: 56px;
        top: 33px;
      }
    }

    .bot_bottonimg {
      width: 100%;
      height: 40px;
      background: url(../assets/btn.png) center no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: black;
      /* font-weight: 600; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1vw;
      font-family: pingfang-r;

      img {
        width: 24px;
        height: 24px;
        margin-right: 1vw;
      }
    }
  }
}

.mainTow {
  width: 100%;
  margin: auto;
  padding-bottom: 20px;

  .mainList {
    width: 90%;
    margin: auto;

    .seion {
      width: 100%;
    }

    .headerdive {
      text-align: center;
      font-size: 20px;
      color: rgb(230, 197, 30);
      margin-bottom: 20px;
      font-family: pingfang-r;
    }

    .equipment_div {
      /* display: grid; */
      /* grid-template-columns: 1fr 1fr 1fr; */
      text-align: center;

      .box {
        display: inline-block;
        font-size: 10px;
        margin-right: 10px;
        margin-top: 20px;
        width: 105px;

        .equipment_name {
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-size: 100% 100%;
          white-space: nowrap;
          /* 禁止文本换行 */
          // overflow: hidden;
          /* 隐藏超出容器的内容 */
          text-overflow: ellipsis;
        }

        img {
          width: 70px;
          height: 70px;
        }
      }

      .box:nth-child(3n),
      .box2 {
        margin-right: 0;
      }
    }
  }

}
</style>
  