<template>
  <div class="app">
    <PcTrgp v-if="pcIpone" />
    <MobileTrgp v-if="!pcIpone" />
  </div>
</template>

<script>
import PcTrgp from './components/PcTrgp.vue'
import MobileTrgp from './components/MobileTrgp.vue';

export default {
  name: 'App',
  components: {
    PcTrgp,
    MobileTrgp
  },
  data() {
    return {
      pcIpone: false
    }
  },
  mounted() {
    var ua = navigator.userAgent,
      isWindowsPhone = /(?:Windows Phone)/.test(ua),
      isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
      isAndroid = /(?:Android)/.test(ua),
      isFireFox = /(?:Firefox)/.test(ua),
      isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(
        ua)),
      isPhone = /(?:iPhone)/.test(ua) && !isTablet,
      isPc = !isPhone && !isAndroid && !isSymbian && !isTablet;
    this.pcIpone = isPc;
  }
}
</script>

<style>
body,
html {
  padding: 0;
  margin: 0;
  font-family:pingfang-s;
}

.app {
  font-family:pingfang-s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  background: url('assets/bg_lxt.png') no-repeat center;
  
  background-size: cover;
}
</style>
